//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import VClamp from 'vue-clamp'
import moment from 'moment'

export default {
  components: {
    VClamp
  },

  props: {
    news: {
      type: Object,
      required: true
    },

    small: {
      type: Boolean,
      default: false
    },

    basePath: {
      type: String,
      required: true
    }
  },

  computed: {
    categoryPath() {
      const { category } = this.news
      const categorySlug = category ? category.slug : 'sem-categoria'

      return `${this.basePath}/${categorySlug}`
    },

    newsPath() {
      const { category, slug } = this.news
      const categorySlug = category ? category.slug : 'sem-categoria'

      return `${this.basePath}/${categorySlug}/${slug}`
    }
  },

  methods: {
    moment
  }
}
