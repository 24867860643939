//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import Vodal from 'vodal'

export default {
  components: {
    Vodal
  },

  props: {
    show: {
      type: Boolean,
      default: false
    },

    popup: {
      type: Object,
      required: true
    }
  }
}
