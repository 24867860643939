//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import VClamp from 'vue-clamp'

export default {
  components: {
    VClamp
  },

  props: {
    solution: {
      type: Object,
      required: true
    },

    basePath: {
      type: String,
      required: true
    }
  }
}
