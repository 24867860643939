//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

export default {
  props: {
    color: {
      type: String,
      default: 'primary'
    },

    // options: default | square | pill
    shape: {
      type: String,
      default: 'default'
    },

    content: {
      type: String,
      default: ''
    },

    actionable: {
      type: Boolean,
      default: false
    }
  },

  data() {
    return {}
  },

  methods: {}
}
