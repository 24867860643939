var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('Vodal',{attrs:{"show":_vm.show,"animation":"fade","class-name":"modal modal-popup","close-button":true,"close-on-click-mask":false,"close-on-esc":true},on:{"hide":function($event){return _vm.$emit('hide')}}},[_c('div',{staticClass:"modal-content"},[(_vm.popup.type === 2)?_c('iframe',{attrs:{"src":("https://www.youtube-nocookie.com/embed/" + (_vm.$helpers.getYTVideoId(
        _vm.popup.url
      )) + "?rel=0&autoplay=0&vq=hd720"),"frameborder":"0","allow":"accelerometer; clipboard-write; encrypted-media; gyroscope; picture-in-picture","allowfullscreen":""}}):_vm._e(),_vm._v(" "),(_vm.popup.type === 1)?_c('a',{class:[
        { hidden: _vm.popup.type === 2 },
        { link: !_vm.popup.url && _vm.popup.type === 1 }
      ],attrs:{"rel":"noopener noreferrer","href":_vm.popup.url ? _vm.popup.url : '',"target":"_blank"}},[_c('img',{attrs:{"src":_vm.popup.main_image.url,"alt":_vm.popup.main_image.name
            ? _vm.popup.main_image.name
            : _vm.popup.main_image.title}})]):_vm._e()])])}
var staticRenderFns = []

export { render, staticRenderFns }