//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

export default {
  props: {
    banners: {
      type: Array,
      default: () => {
        return []
      }
    }
  },

  data() {
    return {
      options: {
        slidesToShow: 1,
        slidesToScroll: 1,
        arrows: false,
        infinite: true,
        autoplay: true,
        autoplaySpeed: 6000,
        dots: true,
        touchThreshold: 100,
        draggable: false,
        fade: true,
        cssEase: 'cubic-bezier(0.7, 0, 0.3, 1)'
      }
    }
  }
}
