//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { mapGetters } from 'vuex'

export default {
  props: {
    type: {
      type: Number,
      required: true
    }
  },

  data() {
    return {
      form: {
        name: '',
        email: ''
      },
      processing: false
    }
  },

  computed: {
    ...mapGetters({
      privacy: 'layout/getPrivacy'
    })
  },

  methods: {
    async send() {
      try {
        this.processing = true

        // Form treatment
        const form = {
          ...this.form,
          type: this.type
        }

        // Api call
        await this.$api.newsletter.send(form)

        // Show modal
        this.$store.dispatch('modal/show', {
          type: 'success',
          title: this.$t('modal.success.title'),
          message: this.$t('modal.success.description')
        })

        // Reset form
        this.form = {
          name: '',
          email: ''
        }

        this.processing = false
      } catch (err) {
        console.log(err)

        this.processing = false

        const message = this.$t('modal.error.description')

        this.$store.dispatch('modal/show', {
          type: 'danger',
          title: this.$t('modal.error.title'),
          message,
          actionUrl: '/'
        })
      }
    }
  }
}
