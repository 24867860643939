//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import VClamp from 'vue-clamp'

export default {
  components: {
    VClamp
  },

  props: {
    basePath: {
      type: String,
      default: '/produtos'
    },

    productsHighlight: {
      type: Array,
      default: () => {
        return []
      }
    }
  },

  data() {
    return {
      options: {
        slidesToShow: 1,
        slidesToScroll: 1,
        arrows: true,
        infinite: true,
        dots: true,
        draggable: false,
        fade: true,
        cssEase: 'cubic-bezier(0.7, 0, 0.3, 1)'
      }
    }
  },

  methods: {
    subcategoryPath(product) {
      const { sub_category } = product
      const subCategorySlug = sub_category ? sub_category.slug : 'sem-categoria'

      return this.localePath(`${this.basePath}/categoria/${subCategorySlug}`)
    },

    productPath(product) {
      const { sub_category, slug } = product
      const subCategorySlug = sub_category
        ? sub_category.slug
        : 'sem-subcategoria'

      return this.localePath(
        `${this.basePath}/categoria/${subCategorySlug}/${slug}`
      )
    }
  }
}
