var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"badge",class:[
    ("badge-" + _vm.color),
    {
      'badge-square': _vm.shape === 'square'
    },
    {
      'badge-pill': _vm.shape === 'pill'
    },
    {
      'badge-actionable': _vm.actionable
    }
  ],attrs:{"role":"tooltip"}},[_c('div',{staticClass:"badge-content",domProps:{"innerHTML":_vm._s(_vm.content)}})])}
var staticRenderFns = []

export { render, staticRenderFns }