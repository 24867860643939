//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { mapGetters } from 'vuex'

export default {
  async asyncData({ $api }) {
    try {
      const response = await $api.home.show()

      const {
        banners,
        popups,
        solutions,
        featured_products,
        most_accessed_products,
        news
      } = response

      // Data treatment
      const treatedPopups = {
        ...popups,
        display: false,
        show: false,
        close_action: true
      }

      return {
        banners,
        popups: treatedPopups,
        solutions,
        featured_products,
        most_accessed_products,
        news
      }
    } catch (err) {
      $api.instance.handleResponseError(err)
    }
  },
  data() {
    return {
      banners: [],
      news: [],
      solutions: [],
      most_accessed_products: [],
      featured_products: [],
      popups: {}
    }
  },

  head() {
    return this.$helpers.seoBuilder(this)
  },

  computed: {
    ...mapGetters({
      popupStore: 'popup/getPopup'
    }),

    getPopup() {
      const popups = this.popupStore
      const lang = this.$i18n.locale

      const popup = popups.find((popup) => {
        return popup.lang === lang
      })

      return popup
    }
  },

  mounted() {
    this.openPopup()
  },

  methods: {
    openPopup() {
      if (this.popups.created_at) {
        this.popups.display = !this.popups.display
        setTimeout(() => {
          this.popups.show = !this.popups.show
        }, 200)
      }
    },

    async closePopup() {
      this.popups.show = !this.popups.show

      // Store updated_at popups
      await this.$store.dispatch('popup/storePopup', this.popups)

      setTimeout(() => {
        this.popups.display = !this.popups.display
      }, 300)
    }
  }
}
