import { render, staticRenderFns } from "./CardNews.vue?vue&type=template&id=68c286ef&scoped=true&"
import script from "./CardNews.vue?vue&type=script&lang=js&"
export * from "./CardNews.vue?vue&type=script&lang=js&"
import style0 from "./CardNews.vue?vue&type=style&index=0&id=68c286ef&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "68c286ef",
  null
  
)

/* custom blocks */
import block0 from "./CardNews.vue?vue&type=custom&index=0&blockType=i18n"
if (typeof block0 === 'function') block0(component)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {BaseUiBadge: require('/var/www/html/intelli.com.br/components/base/ui/Badge.vue').default})
